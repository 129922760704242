import './Etudes.css';

const myStudies = (
							<div id="vContainer">
								<div class="hContainerStudies">
									<div class="sizeContainerLeft">
										<p class="titleParagraph"><strong>ESGI Paris</strong></p>
										<p class="titleParagraph">2020 - 2023</p>
									</div>
									<p class="separator"> - </p>
									<div class="sizeContainerRight">
										<p class="textContainerStudies">
											J'ai pu obtenir mon mastère en Ingénierie de la 3D et des jeux vidéo dans cette école. J'ai eu 
                                            la chance d'apprendre durant mes 3 années là-bas différents domaines informatiques, l'ESGI se veut être un établissement qui forme des développeurs "couteau suisse", on a donc des matières du tronc commun comme de l'algorithmique et du réseau. 
										</p>
									</div>
								</div>
								<div class="hContainerStudies">
									<div class="sizeContainerLeft">
										<p class="titleParagraph"><strong>EFREI Villejuif</strong></p>
										<p class="titleParagraph">2017 - 2019</p>
									</div>
									<p class="separator"> - </p>
									<div class="sizeContainerRight">
										<p class="textContainerStudies">
											Ecole d’ingénieurs généraliste en informatique. 
											J'ai commencé mes premiers langages de programmation en C et Java.
											J'ai choisi comme spécialité le Business Intelligence qui est de l'informatique décisionnelle, 
                                            c'est-à-dire qu'on travaille énormément avec les données.
										</p>
									</div>
								</div>
								<div class="hContainerStudies">
									<div class="sizeContainerLeft">
										<p class="titleParagraph"><strong>ENCPB Classe préparatoire PCSI</strong></p>
										<p class="titleParagraph">2012 - 2014</p>
									</div>
									<p class="separator"> - </p>
									<div class="sizeContainerRight">
										<p class="textContainerStudies">
											Spécialité Science de l'ingénieur
											J'ai étudié les Mathématiques, la Physique et Chimie niveau préparatoire.
											Dans les cours Science de l'ingénieur, j'ai pu toucher à mon premier logiciel 3D "SOLIDWORKS". 
										</p>
									</div>
								</div>
								<div class="hContainerStudies">
									<div class="sizeContainerLeft">
										<p class="titleParagraph"><strong>Lycée Turgot</strong></p>
										<p class="titleParagraph">2009 - 2012</p>
									</div>
									<p class="separator"> - </p>
									<div class="sizeContainerRight">
										<p class="textContainerStudies">
											Ayant des préférences pour les matières scientifiques, 
											j'ai choisi la filière Scientifique avec comme spécialité les Mathématiques.
										</p>
									</div>
								</div>
							</div>);

export default myStudies;