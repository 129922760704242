import './Presentation.css';

const introductionText = (<div id="presentationSection">
							<p id="presentation">Bonjour ! Je m'appelle Billy, je suis diplômé d'un master en ingénierie de la 3D et des jeux vidéo.
							Je m'intéresse à tout ce qui touche à la 3D, il est donc évident que je suis un grand passionné des jeux vidéo.
							Durant ma formation à l'ESGI, j'ai pu explorer différents domaines liés à la 3D.
							Comme la vision par ordinateur, le machine learning, la réalité augmentée/virtuelle,
							la modélisation et l'animation 3D et plein d'autres sujets qui méritent des pages entières !</p>
						  </div>);

export default introductionText;