import './Projets.css';
import ImageComponent from "./ImageComponent";

const projectSection = (<div id="vContainer">
                                <div class="hContainer">
									<div class="sizeContainerL">
											<iframe class="videoProject" title="GameplayVideo" src="https://www.youtube.com/embed/99_FNpYN0Nk" allow="fullscreen" allowfullscreen></iframe>
									</div>
									<div class="sizeContainerR">
										<h4 class="textContainer">Démonstration de gameplay</h4>
										<p class="textContainer">J'ai voulu ici vous montrer les différentes prouesses techniques que j'ai pu acquérir durant mes années de développement sur Unity. Création d'effets VFX.
                                        Implémentation de l'algorithme des BOIDS sur des squelettes animés.
                                        </p>
									</div>
								</div>
                                <div class="hContainer">
									<div class="sizeContainerL">
											<iframe class="videoProject" title="monopolyGame" src="https://www.youtube.com/embed/X466KMyw0Jg" allow="fullscreen" allowfullscreen></iframe>
									</div>
									<div class="sizeContainerR">
										<h4 class="textContainer">Monopoly (En développement)</h4>
										<p class="textContainer">Un monopoly, où l'on peut jouer jusqu'à 4 joueurs en ligne.
                                        J'ai utilisé Photon un asset d'unity permettant de développer le côté réseau du jeu.
                                        Le jeu n'est pas encore terminé, mais il a déjà une base pour pouvoir être testé.
                                        </p>
										<div className="downloadContainer">
											<a className="downloadLink" href='https://drive.google.com/file/d/148vd84VHD3pwtPNBQhHxtxP_Wab7WB9E/view?usp=sharing' download>Téléchargement</a>
											<p className="downloadInfo">(Pour Windows)</p>
                                            <a className="downloadLink" href='https://drive.google.com/file/d/1u-7Be0RQttVjRabNux9f3rXLyszD9evR/view?usp=sharing' download>Téléchargement</a>
											<p className="downloadInfo">(Pour Android)</p>
										</div>

									</div>
								</div>
                                <div class="hContainer">
									<div class="sizeContainerL">
											<iframe class="videoProject" title="PlatformGame" src="https://www.youtube.com/embed/vbEYp-pfTdM" allow="fullscreen" allowfullscreen></iframe>
									</div>
									<div class="sizeContainerR">
										<h4 class="textContainer">Platformer Game (1 mois)</h4>
										<p class="textContainer">Jeu de plateforme où l'on incarne un ours bipède voulant rentrer chez lui,
                                            malheureusement, des êtres malfaisants lui bloquent la route.</p>
										<div className="downloadContainer">
											<a className="downloadLink" href='https://drive.google.com/file/d/1Xu2iHQAORYIQBUCCBj3CKxy5_ufy9ad3/view?usp=sharing' download>Téléchargement</a>
											<p className="downloadInfo">(Pour Windows)</p>
										</div>
									</div>
								</div>
                                <div class="hContainer">
									<div class="sizeContainerL">
											<iframe class="videoProject" title="LavalVirtualVideo" src="https://www.youtube.com/embed/2OuXJQhzuEk" allow="fullscreen" allowfullscreen></iframe>
									</div>
									<div class="sizeContainerR">
										<h4 class="textContainer">Laval Virtual - Concours de réalité virtuelle</h4>
										<p class="textContainer">Le thème du concours était "handicap et les technologies immersives". Nous étions un groupe de 4 développeurs, bien que la technologie de la réalité virtuelle était nouvelle pour nous, nous avons pu implémenter un projet avec des mini-jeux variés. Nous avions 4 jours pour terminer notre projet.
                                        </p>
									</div>
								</div>
								<div class="hContainer">
									<div class="sizeContainerL">
										<video class="videoProject" controls>
											<source src="./becomeWizard.mp4" type="video/mp4"/>
										</video>
									</div>
									<div class="sizeContainerR">
										<h4 class="textContainer">BecomeWizard (1 an)</h4>
										<p class="textContainer">Projet de fin d'études dans lequel l'ensemble de nos connaissances acquises à l'ESGI ont été exploitées.
										RPG inspiré de Stardew Valley et de My Time at Portia développé sur Unity.</p>
										<div className="downloadContainer">
											<a className="downloadLink" href='https://drive.google.com/file/d/1h7ptbWcNSj3Yg2gQgK1vRg-QyJ-uM-Nt/view?usp=sharing' download>Téléchargement</a>
											<p className="downloadInfo">(Pour Windows)</p>
										</div>
									</div>
								</div>
								<div class="hContainer">
									<div class="sizeContainerL">
										<video class="videoProject" controls>
											<source src="./elemsBattle.mp4" type="video/mp4"/>
										</video>
									</div>
									<div class="sizeContainerR">
										<h4 class="textContainer">Elems' Battle (9 mois de développement)</h4>
										<p class="textContainer">Voici une vidéo du projet annuel que j'avais développé avec 2
										de mes camarades à ESGI. C'est un jeu PUBG/Fortnite like, en
										d'autres mots, c'est un jeu de type Battle Royal. Nous avons codé le jeu
										en C# sous le moteur de jeu Unity avec le Framework Photon pour
										permettre de jouer à plusieurs.</p>
										<div className="downloadContainer">
											<a className="downloadLink" href='https://drive.google.com/file/d/1XfvOdkn7I8EgFU33YequLARUh63Irqoq/view?usp=sharing' download>Téléchargement</a>
											<p className="downloadInfo">(Pour Windows)</p>
										</div>
									</div>
								</div>
								<div class="hContainer">
									<div class="sizeContainerL">
										<video class="videoProject" controls>
											<source src="./ProceduralMonster.mp4" type="video/mp4"/>
										</video>
									</div>
									<div class="sizeContainerR">
										<h4 class="textContainer">Procedural Monster (1 mois de développement)</h4>
										<p class="textContainer">Pour développer ce projet, nous nous sommes inspirés d'un logiciel de
										création de personnage qui est "MakeHuman". En faisant des
										recherches, nous avons pu constater qu'il n'y avait pas de logiciel de
										création de créature, nous avons donc décidé de développer cette
										idée.</p>
									</div>
								</div>
                                <div class="hContainer">
									<div class="sizeContainerL">
										<ImageComponent path="./onlineChess.png"/>
									</div>
									<div class="sizeContainerR">
										<h4 class="textContainer">Online Chess (1 semaine de développement)</h4>
										<p class="textContainer">Jeu d'échecs en ligne en 3D. Il a été développé sur Unity
                                        avec l'asset Photon pour gérer le côté réseau du jeu.
                                        </p>
                                        <div className="downloadContainer">
											<a className="downloadLink" href='https://drive.google.com/file/d/1zqtp8JRD8nlv0M9Z-OvWrPYRf30ca1OS/view?usp=sharing' download>Téléchargement</a>
											<p className="downloadInfo">(Pour Windows)</p>
										</div>
									</div>
								</div>
						</div>
						);

export default projectSection;