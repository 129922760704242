import './Competences.css'

const skillsSection = (<div id="VSContainer">
		<div class="HSContainer">
			<div class="ItemSkill"><strong>C/C++</strong></div>
			<div class="ItemSkill"><strong>Javascript</strong></div>
		</div>
		<div class="HSContainer">
			<div class="ItemSkill"><strong>Python</strong></div>
			<div class="ItemSkill"><strong>C#</strong></div>
		</div>
		<div class="HSContainer">
			<div class="ItemSkill"><strong>HTML/CSS</strong></div>
			<div class="ItemSkill"><strong>Unity</strong></div>
		</div>
		<div class="HSContainer">
			<div class="ItemSkill"><strong>React js</strong></div>
			<div class="ItemSkill"><strong>SQL</strong></div>
		</div>
	</div>);

export default skillsSection;