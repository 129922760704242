import React from 'react';



export default function Section({title, subtitle, id}){
	const [isVisible, setVisible] = React.useState(true);
  	const domRef = React.useRef();
  	React.useEffect(() => {
  	let stayVisible = false;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(
      	function(entry){
      		if(entry.isIntersecting){
      			stayVisible = true;
      		}
  	  		setVisible(stayVisible);
		});
    });
    const copyRef = domRef.current;
    observer.observe(copyRef);
    return () => observer.unobserve(copyRef);
  }, []);
	return(
		<div className="section" id={id}>
			<div ref={domRef} className={`section-content ${ isVisible ? 'is-visible' : ''}`}>
				<h1>{title}</h1>
				<div>{subtitle}</div>
			</div>
		</div>);
}