import React from "react";
import './ImageComponent.css'

export default class ImageComponent extends React.Component {
  state = { isOpen: false };

  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
    console.log('cliked');
  };

  render() {
    return (
      <div className="imageContainer">
        <img
          className="imageSetup"
          src={this.props.path}
          onClick={this.handleShowDialog}
          alt="nothing"
        />
        {this.state.isOpen && (
          <dialog
            className="dialog"
            open
            onClick={this.handleShowDialog}
          >
            <img
              className="image"
              src={this.props.path}
              onClick={this.handleShowDialog}
              alt="nothing"
            />
          </dialog>
        )}
      </div>
    );
  }
}