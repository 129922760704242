import React from 'react';
import ReactDOM from 'react-dom';
import imgWizard from './images/BecomeWizard.png';
import imgElement from './images/ElementBattle.png';
import wifiInter from './images/WifiPicture.png';
import linkedin from './images/linkedin.png';
import './index.css';
import Navbar from './Navbar';
import Section from './Section';
import myStudies from "./Etudes";
import introductionText from "./Presentation";
import projectSection from "./Projets";
import myExpSection from './Exp';
import skillsSection from './Competences'

const imageAnnualProject = <img src={imgWizard} alt="Projet annuel" className="images"/>;
const imageBattleRoyalProject = <img src={imgElement} alt="Projet annuel Battle royal" className="images"/>;
const imageWifiInterface = <img src={wifiInter} alt="Interface Wifi" className="images"/>;

const myVideo = (
    <div id="video-container">
        <div class="overlay"></div>
        <video className='video' autoPlay loop muted>
            <source src="./Intro.mp4" type='video/mp4' />
        </video>
        <p class="textIntro">Bienvenue sur mon Portfolio !</p>
    </div>
);


const App = (
	<div className="App">
        <Navbar />
        {myVideo}
		<Section
          title="Présentation"
          subtitle={introductionText}
          id="section1"
        />
        <Section
          title=""
          subtitle={imageAnnualProject}
          id=""
        />
        <Section
          title="Etudes"
          subtitle={myStudies}
          id="section2"
        />
        <Section
          title=""
          subtitle={imageBattleRoyalProject}
          id=""
        />
        <Section
          title="Projets"
          subtitle={projectSection}
          id="section3"
        />
        <Section
          title=""
          subtitle={imageWifiInterface}
          id="imageLink3"
        />
        <Section
          title="Expériences Professionelles"
          subtitle={myExpSection}
          id="section4"
        />
        <Section
          title="Compétences"
          subtitle={skillsSection}
          id="section5"
        />
        <footer>
			<div id="footContainer"> 
				<div className="footRowContainer"><p className="foot">©2024 Billy KOUMMARASY, tous droits réservés.</p></div>
				<div className="footRowContainer">
                    <div className="icon_mail"> 
                        <a id="link" href="https://fr.linkedin.com/in/bkoummarasy">
                            <img id="icon" alt="icone linkedin" src={linkedin} />
                        </a>
                        <p id="email">billy.koummarasy@efrei.net</p>
                    </div>
                </div>
			</div>
		</footer>
    </div>
);
ReactDOM.render(App, document.getElementById('app'));
