import React, {Component} from 'react';
import {Link, animateScroll as scroll} from 'react-scroll';

export default class NavBar extends Component {
	scrollToTop = () => {
		scroll.scrollToTop();
	}

	render(){
		return(
		<nav className="nav" id="navbar">
			<div className="nav-content">
				<h1 id="nav-item-name" onClick={this.scrollToTop}>
          KOUMMARASY Billy
        </h1>
				<ul className="nav-items">
					<li className="nav-item">
						<Link 
							activeClass="active" 
							to="section1" 
							spy={true} 
							smooth={true} 
							offset={-70} 
							duration={500}
							>
							Présentation
						</Link>
					</li>
					<li className="nav-item">
              			<Link
                			activeClass="active"
                			to="section2"
                			spy={true}
                			smooth={true}
                			offset={-70}
                			duration={500}
              				>
                			Etudes
              			</Link>
            		</li>
            		<li className="nav-item">
              			<Link
                			activeClass="active"
                			to="section3"
                			spy={true}
                			smooth={true}
                			offset={-70}
                			duration={500}
              				>
                			Projets
              			</Link>
           			</li>
            		<li className="nav-item">
              			<Link
                			activeClass="active"
                			to="section4"
                			spy={true}
               				smooth={true}
               	 			offset={-70}
                			duration={500}
              				>
                			Expériences
              			</Link>
            		</li>
            		<li className="nav-item">
              			<Link
                			activeClass="active"
                			to="section5"
                			spy={true}
                			smooth={true}
                			offset={-70}
                			duration={500}
              				>
                			Competences
              			</Link>
           			</li>
           		</ul>
           	</div>
        </nav>   	
	);
	}
}